import React from "react";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Services7 from "./../Elements/Services7";
import Header from "../Common/Header";
import Testimonials2 from "../Elements/Testimonials2";

var bnrimg = require("./../../images/banner/service.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Our Service"
            pagename="Services"
            description="When I'm working on a problem, I never think about its beauty, I just think about how to solve the problem, but when I finish it, if the solution is not beautiful, I know it's wrong."
            bgimage={bnrimg}
          />

          <Services7 />
          <Testimonials2 separatoralignment="separator-center" />
        </div>
        <Footer />
      </>
    );
  }
}

export default Service1;
