import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class Plans1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">Our Plans</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <div className="row no-gutters justify-content-center">
                  <div className="col-lg-4 col-md-4 col-sm-6  m-b30">
                    <div className="pricingtable-wrapper pricing-table-style-5 bg-white">
                      <div className="pricingtable-inner">
                        <div className="pricingtable-title">
                          <h4>Consulting Package </h4>
                        </div>
                        <div className="pricingtable-price">
                          <span className="pricingtable-bx">₹63</span>
                          <span className="pricingtable-type">
                            ( Per/Sq.ft )
                          </span>
                        </div>
                        <ul className="pricingtable-features text-black">
                          <li>2D & 3D Floor Plan </li>
                          <li>3D Elevation </li>
                          <li>Structural Designs</li>
                          <li>Electrical Layout </li>
                          <li>Grill & Gate Design </li>
                        </ul>
                        <div className="pricingtable-price">
                          <span className="pricingtable-bx">₹100</span>
                          <span className="pricingtable-type">
                            ( Per/Sq.ft )
                          </span>
                        </div>
                        <ul className="pricingtable-features text-black">
                          <li>2D & 3D Floor Plan</li>
                          <li>3D Elevation </li>
                          <li>Structural Designs</li>
                          <li>Electrical Layout </li>
                          <li>Site Visit Include </li>
                          <li>Grill & Gate Design</li>
                          <li>BOQ (Bill of Quantity) </li>
                        </ul>
                        <div className="pricingtable-footer">
                          <HashLink
                            smooth
                            to="/pricing-table#price_enquiry"
                            className="site-button-link white"
                          >
                            Enquiry
                          </HashLink>
                        </div>
                        <div className="overlay-main bg-white opacity-07" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 m-b30">
                    <div className="pricingtable-wrapper  pricing-table-style-5 bg-white">
                      <div className="pricingtable-inner pricingtable-highlight shadow-lg">
                        <div className="pricingtable-title">
                          <h4>Interior Package</h4>
                        </div>
                        <div className="pricingtable-price">
                          <span className="pricingtable-bx">₹3.25 Lak</span>
                          <span className="pricingtable-type">( 1 BHK )</span>
                        </div>
                        <ul className="pricingtable-features text-black">
                          <li>
                            <b>HALL</b>
                          </li>
                          <li>Tv Unit, Flase Ceiling</li>
                          <li>
                            <b>Kitchen</b>{" "}
                          </li>
                          <li>Semi Modular Kitchen</li>
                          <li>
                            <b>Bedroom</b>
                          </li>
                          <li>Queen Size Cot, 3Door Wardrobe, Dressing Unit</li>
                        </ul>
                        <div className="pricingtable-price">
                          <span className="pricingtable-bx">₹7.5 Lak</span>
                          <span className="pricingtable-type">( 2 BHK )</span>
                        </div>
                        <ul className="pricingtable-features text-black">
                          <li>
                            <b>HALL</b>
                          </li>
                          <li>Tv Unit, Flase Ceiling, 5 Sheeter Sofa</li>

                          <li>
                            <b>Kitchen</b>
                          </li>
                          <li> Modular Kitchen with Accessories </li>
                          <li>
                            <b>Dinning </b>
                          </li>
                          <li>4 Sheet Dining Table & Crockery Unit</li>
                          <li>
                            <b>Master Bedroom</b>
                          </li>
                          <li> King Size Cot, 3Door Wardrobe, Dressing Unit</li>

                          <li>Bed Bedsheets & Pillows</li>
                          <li>
                            <b>Guest Bed</b>
                          </li>
                          <li>Queen Size Cot, 2Door Wardrobe</li>
                          <li>Dressing Unit, Bed Bedsheets & Pillows</li>
                        </ul>
                        <div className="pricingtable-footer">
                          <HashLink
                            smooth
                            to="/pricing-table#price_enquiry"
                            className="site-button-link white"
                          >
                            Enquiry
                          </HashLink>
                        </div>
                        <div className="overlay-main bg-white opacity-07" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4  col-sm-6 m-b30">
                    <div className="pricingtable-wrapper  pricing-table-style-5 bg-white">
                      <div className="pricingtable-inner">
                        <div className="pricingtable-title">
                          <h4>Construction Package</h4>
                        </div>
                        <div className="pricingtable-price">
                          <span className="pricingtable-bx">₹1,999</span>
                          <span className="pricingtable-type">
                            ( Per/Sq.ft )
                          </span>
                        </div>
                        <ul className="pricingtable-features text-black">
                          <li>Architectural Design Construction </li>
                          <li>Semi Framed Structure Building</li>
                          <li>ISI Brand Materials we Used</li>
                          <li>25+ Plus Quality Check</li>
                          <li>Including Water tank & Septic Tank</li>
                          <li></li>
                          <li></li>
                        </ul>
                        <div className="pricingtable-price">
                          <span className="pricingtable-bx">₹2,399</span>
                          <span className="pricingtable-type">
                            ( Per/Sq.ft )
                          </span>
                        </div>
                        <ul className="pricingtable-features text-black">
                          <li>Architectural Design Set of Drawing </li>
                          <li>50+ Quality Check</li>
                          <li>Fully Framed Structure </li>
                          <li>ISI Brand Materials </li>
                          <li>Including Water and Septic Tank</li>
                          <li>Trending Elevation Design</li>
                        </ul>
                        <div className="pricingtable-footer">
                          <HashLink
                            smooth
                            to="/pricing-table#price_enquiry"
                            className="site-button-link white"
                          >
                            Enquiry
                          </HashLink>
                        </div>
                        <div className="overlay-main bg-white opacity-07" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Plans1;
