import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/banner/5.jpg");

class FontIcons extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Icon Font Style"
            pagename="Icon Fonts"
            description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
            bgimage={bnrimg}
          />

          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{
                      backgroundImage: "url(images/background/cross-line2.png)",
                    }}
                  >
                    <h3 className="sep-line-one">Icon Fonts</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="section-content">
                <div className="sx-box">
                  <div className="icon-font">
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-sketch" />
                      <div className="class-name">.flaticon-sketch</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-stairs" />
                      <div className="class-name">.flaticon-stairs</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-window" />
                      <div className="class-name">.flaticon-window</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-skyline" />
                      <div className="class-name">.flaticon-skyline</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-bed" />
                      <div className="class-name">.flaticon-bed</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-door" />
                      <div className="class-name">.flaticon-door</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-living-room" />
                      <div className="class-name">.flaticon-living-room</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-door-1" />
                      <div className="class-name">.flaticon-door-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-window-1" />
                      <div className="class-name">.flaticon-window-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-closet" />
                      <div className="class-name">.flaticon-closet</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-stairs-1" />
                      <div className="class-name">.flaticon-stairs-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-vase" />
                      <div className="class-name">.flaticon-vase</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-desk" />
                      <div className="class-name">.flaticon-desk</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-lamp" />
                      <div className="class-name">.flaticon-lamp</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-armchair" />
                      <div className="class-name">.flaticon-armchair</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-window-2" />
                      <div className="class-name">.flaticon-window-2</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-bed-1" />
                      <div className="class-name">.flaticon-bed-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-chair" />
                      <div className="class-name">.flaticon-chair</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-lamp-1" />
                      <div className="class-name">.flaticon-lamp-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-table" />
                      <div className="class-name">.flaticon-table</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-mirror" />
                      <div className="class-name">.flaticon-mirror</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-bookcase" />
                      <div className="class-name">.flaticon-bookcase</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-cactus" />
                      <div className="class-name">.flaticon-cactus</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-blueprint" />
                      <div className="class-name">.flaticon-blueprint</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-tv" />
                      <div className="class-name">.flaticon-tv</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-chest" />
                      <div className="class-name">.flaticon-chest</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-desk-1" />
                      <div className="class-name">.flaticon-desk-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-chandelier" />
                      <div className="class-name">.flaticon-chandelier</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-clock" />
                      <div className="class-name">.flaticon-clock</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-coat-stand" />
                      <div className="class-name">.flaticon-coat-stand</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-chest-1" />
                      <div className="class-name">.flaticon-chest-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-3d" />
                      <div className="class-name">.flaticon-3d</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-architect" />
                      <div className="class-name">.flaticon-architect</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-barrier" />
                      <div className="class-name">.flaticon-barrier</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-bed-2" />
                      <div className="class-name">.flaticon-bed-2</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-blueprint-1" />
                      <div className="class-name">.flaticon-blueprint-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-building" />
                      <div className="class-name">.flaticon-building</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-building-1" />
                      <div className="class-name">.flaticon-building-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-certificate" />
                      <div className="class-name">.flaticon-certificate</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-contract" />
                      <div className="class-name">.flaticon-contract</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-cornice" />
                      <div className="class-name">.flaticon-cornice</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-dimensions" />
                      <div className="class-name">.flaticon-dimensions</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-door-2" />
                      <div className="class-name">.flaticon-door-2</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-drawing-table" />
                      <div className="class-name">.flaticon-drawing-table</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-electric-pole" />
                      <div className="class-name">.flaticon-electric-pole</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-fence" />
                      <div className="class-name">.flaticon-fence</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-helmet" />
                      <div className="class-name">.flaticon-helmet</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-home" />
                      <div className="class-name">.flaticon-home</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-home-1" />
                      <div className="class-name">.flaticon-home-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-lift" />
                      <div className="class-name">.flaticon-lift</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-living-room-1" />
                      <div className="class-name">.flaticon-living-room-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-house" />
                      <div className="class-name">.flaticon-house</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-measuring-tape" />
                      <div className="class-name">.flaticon-measuring-tape</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-parquet" />
                      <div className="class-name">.flaticon-parquet</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-blueprint-2" />
                      <div className="class-name">.flaticon-blueprint-2</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-power-strip" />
                      <div className="class-name">.flaticon-power-strip</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-project" />
                      <div className="class-name">.flaticon-project</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-paint-roller" />
                      <div className="class-name">.flaticon-paint-roller</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-ruler" />
                      <div className="class-name">.flaticon-ruler</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-table-1" />
                      <div className="class-name">.flaticon-table-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-sketch-1" />
                      <div className="class-name">.flaticon-sketch-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-ruler-1" />
                      <div className="class-name">.flaticon-ruler-1</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-set-square" />
                      <div className="class-name">.flaticon-set-square</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-table-2" />
                      <div className="class-name">.flaticon-table-2</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-table-3" />
                      <div className="class-name">.flaticon-table-3</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-bed-3" />
                      <div className="class-name">.flaticon-bed-3</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-stairs-2" />
                      <div className="class-name">.flaticon-stairs-2</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-brick-wall" />
                      <div className="class-name">.flaticon-brick-wall</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-real-estate" />
                      <div className="class-name">.flaticon-real-estate</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-window-3" />
                      <div className="class-name">.flaticon-window-3</div>
                    </div>
                    <div className="icon-font-block">
                      <div className="flat-icon flaticon-window-4" />
                      <div className="class-name">.flaticon-window-4</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default FontIcons;
