import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/about-slider/karthick-seo.jpg");

const About2 = () => {
  return (
    <>
      <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12 ">
                <div
                  className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                ></div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="about-home-2">
                  <h3 className="abtH3">- About Us -</h3>
                  <h2 className="m-t0 sx-tilte">ARK Design space</h2>
                  <p>
                    Embark on a journey of innovation and precision with Karthik
                    Selvaraj, a distinguished Civil Engineering graduate from
                    Anna University Coimbatore. His unwavering commitment to
                    excellence and a wealth of experience in the field make him
                    a luminary in the realm of construction.
                  </p>
                  <p>
                    As the Design Head & Associate Partner at Arch4 Foundation
                    from 2008 to 2020, arK design space has spent the past 12
                    years shaping the landscape of Building Construction
                    Management & Construction. With a strong foundation in both
                    expertise and ethics, we stand as a testament to
                    unparalleled quality.
                  </p>
                  {/* <div className="text-left">
                    <NavLink to={"/about-2"} className="site-button-link">
                      Read More
                    </NavLink>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About2;
