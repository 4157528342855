import React from "react";

const achievements1 = [
  {
    title: "International Architect & Interior Awards",
    description:
      "We are thrilled to announce that ARK Design Space has been honored with the prestigious International Architect & Interior Awards.",
    date: "01",
    month: "April",
    year: "2023",
  },
  {
    title: "NNI Business World",
    description:
      "The NNI Business World Award acknowledges businesses that demonstrate exceptional achievements, innovation, and a commitment to excellence.",
    date: "03",
    month: "June",
    year: "2023",
  },
  {
    title: "Innovative Design Solution Awards",
    description:
      "We are overjoyed to announce that ARK Design Space has been honored with the distinguished Innovative Design Solution Awards.",
    date: "20",
    month: "June",
    year: "2023",
  },
];

const achievements2 = [
  {
    title: "Awards for Interior Design By Buyant Media",
    description:
      "We are thrilled to announce that ARK Design Space has been honored with the prestigious Awards for Interior Design presented by Buyant Media. This recognition is a testament to our commitment to excellence and innovation in the realm of interior design. ",
    date: "28",
    month: "Sep",
    year: "2023",
  },
  {
    title: "NNI Archiver Awards",
    description:
      "We are delighted to announce that ARK Design Space has been recognized with the prestigious NNI Archiver Awards. This accolade is a testament to our dedication to innovation and excellence in the field of design.",
    date: "25",
    month: "Nov",
    year: "2023",
  },
];

var bgimg1 = require("./../../images/background/bg-map.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class Achievements1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">We Achieved</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content ">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 m-b30">
                  {achievements1.map((item, index) => (
                    <div className="sx-box our-story text-white" key={index}>
                      <div className="mt-media our-story-info d-flex justify-content-start">
                        <div className="our-story-time">
                          <span className="date">{item.date}</span>
                          <span className="month">{item.month}</span>
                        </div>
                        <h4 className="text-uppercase our-story-year">
                          {item.year}
                        </h4>
                      </div>
                      <div className="our-story-detail">
                        <h2 className="achiH2">{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  {achievements2.map((item, index) => (
                    <div className="sx-box our-story text-white" key={index}>
                      <div className="mt-media our-story-info d-flex justify-content-start">
                        <div className="our-story-time">
                          <span className="date">{item.date}</span>
                          <span className="month">{item.month}</span>
                        </div>
                        <h4 className="text-uppercase our-story-year">
                          {item.year}
                        </h4>
                      </div>
                      <div className="our-story-detail">
                        <h2 className="achiH2">{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Achievements1;
