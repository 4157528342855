import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import sanityClient from "../../client";

var bgimg1 = require("./../../images/background/cross-line2.png");

const Projects4 = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");

    sanityClient
      .fetch(
        `*[_type == "post"] | order(_createdAt asc) [0..5]{
            title,
            slug,
            category,
            mainImage{
              asset->{
              _id,
              url
            }
          }
        }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  const options = {
    loop: true,
    autoplay: false,
    center: false,
    items: 3,
    margin: 40,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      640: {
        items: 2,
        margin: 15,
      },
      800: {
        items: 3,
        margin: 20,
      },
      1200: {
        items: 4,
      },
    },
  };
  if (!allPostsData) {
    return <h3>Loading...</h3>;
  }
  return (
    <>
      <div className="section-full p-tb80 bg-white inner-page-padding">
        <div className="container-fluid">
          <div className="section-content">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                >
                  <h3 className="sep-line-one">arK Projects</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="work-carousel-outer">
              <OwlCarousel
                className="owl-carousel mfp-gallery project-carousel project-carousel4 owl-btn-vertical-center"
                {...options}
              >
                {allPostsData &&
                  allPostsData.map((post, index) => (
                    <div
                      key={index}
                      className={` item fadingcol overflow-hide`}
                    >
                      <div className="sx-box   image-hover-block">
                        <div className="sx-thum-bx">
                          <img src={post.mainImage.asset.url} alt="" />
                        </div>
                        <div className="sx-info  p-t20 text-white">
                          <h4 className="sx-tilte">
                            <NavLink
                              to={`/project-detail/${post.slug.current}`}
                            >
                              {post.title}
                            </NavLink>
                          </h4>
                          <p className="m-b0">{post.category}</p>
                        </div>
                        <a className="mfp-link" href={post.mainImage.asset.url}>
                          <i className="fa fa-arrows-alt" />
                        </a>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects4;
