import React from "react";
import { NavLink } from "react-router-dom";
var bgimg3 = require("./../../images/background/cross-line2.png");

const services = [
  {
    count: "01",
    title: "3D Exterior and Interior Designs",
    flaticon: "flaticon-skyline",
    image: require("./../../images/services/service-projects/1.jpg"),
    description:
      "Immerse yourself in visually stunning and immersive design concepts.",
  },
  {
    count: "02",
    title: "2D Design Planning and Structurals",
    flaticon: "flaticon-sketch-1",
    image: require("./../../images/services/service-projects/2.jpg"),
    description:
      "Precision and planning converge for structurally sound designs.",
  },

  {
    count: "03",
    title: "Construction and Contract",
    flaticon: "flaticon-barrier",
    image: require("./../../images/services/service-projects/3.jpg"),
    description:
      "From blueprint to reality, we oversee the entire construction process.",
  },
  {
    count: "04",
    title: "Interior Makeovers",
    image: require("./../../images/services/service-projects/4.jpg"),
    flaticon: "flaticon-stairs",
    description: "Transform your spaces with our expertise in interior design.",
  },

  {
    count: "05",
    title: "Plans and Projects",
    flaticon: "flaticon-sketch",
    image: require("./../../images/services/service-projects/5.jpg"),
    description:
      "We provide a range of architectural 3D modeling services to our customers...",
  },

  {
    count: "06",
    title: "Decoration",
    flaticon: "flaticon-door",
    image: require("./../../images/services/service-projects/6.jpg"),
    description:
      "We combine Interior and Exterior Design services and often provide them...",
  },
];

class Services7 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 bg-white">
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg3 + ")" }}
                >
                  <h2 className="sep-line-one">arK Services</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {services.map((item, index) => (
                <div className="col-lg-4 col-md-12 m-b30" key={index}>
                  <div
                    className="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
                    style={{ backgroundImage: "url(" + item.image + ")" }}
                  >
                    <div className="overlay-main bg-black opacity-05" />
                    <div className="bg-content-mid-outer">
                      <div className="bg-content-mid">
                        <div className="sx-icon-box-wraper center   text-white">
                          <div className="icon-lg m-b15">
                            <span className="icon-cell">
                              <i className={item.flaticon} />
                            </span>
                          </div>
                          <div className="icon-content m-b30">
                            <h4 className="sx-tilte">
                              {" "}
                              <NavLink to={""} className="sx-text-white">
                                {item.title}
                              </NavLink>
                            </h4>
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <span className="bgcall-block-number">
                          {item.count}
                        </span>
                        <div className="bg-overlay" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services7;
