import React from "react";

var bgimg1 = require("./../../images/background/bg-form.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");
var bgimg3 = require("./../../images/background/bg-map.png");

const ContactUs1 = () => {
  const priceSub = (e) => {
    e.preventDefault();
    alert("Submit Successfully!.Contact you soon...");
    e.target.reset();
  };
  return (
    <>
      <div
        className="section-full p-tb80 mobile-page-padding bg-center bg-no-repeat bg-fixed"
        id="price_enquiry"
        style={{
          backgroundImage: "url(" + bgimg1 + ")",
          backgroundSize: "cover",
        }}
      >
        <div className="section-content">
          <div className="container get-in-touch-form">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">Price Enquiry</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="contact-home1-left contact-home1-left-v2 shadow-lg bg-white p-a30"
                  style={{ backgroundImage: "url(" + bgimg3 + ")" }}
                >
                  <form
                    className=" form-transparent"
                    method="post"
                    onSubmit={priceSub}
                  >
                    <div className="input input-animate">
                      <input
                        type="text"
                        name="username"
                        placeholder="Name"
                        id="name"
                        required
                      />
                      <span className="spin" />
                    </div>
                    <div className="input input-animate">
                      <select name="plan" required>
                        <option value="">Select Plan</option>
                        <option value="STARTER">STARTER</option>
                        <option value="ECONOMY">ECONOMY</option>
                        <option value="INTERMEDIATE">INTERMEDIATE</option>
                        <option value="ADVANCED">ADVANCED</option>
                      </select>
                      <span className="spin" />
                    </div>
                    <div className="input input-animate">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Id"
                        id="email"
                        required
                      />
                      <span className="spin" />
                    </div>
                    <div className="input input-animate">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Phone No"
                        id="Phone"
                        required
                      />
                      <span className="spin" />
                    </div>
                    <div className="input input-animate">
                      <textarea
                        placeholder="Your message"
                        name="message"
                        id="message"
                        required
                        defaultValue={""}
                      />
                      <span className="spin" />
                    </div>
                    <div className="text-left p-t10">
                      <button
                        type="submit"
                        className="site-button-secondry btn-half "
                      >
                        <span> Submit Now</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs1;
