import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";

import sanityClient from "../../client";

function FooterPost() {
  const [aallPostsData, setAallPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(_createdAt asc) [0..2] {
            title,
            slug,
            category,
            publishedAt,
            mainImage{
              asset->{
              _id,
              url
            }
          }
        }`
      )
      .then((data) => setAallPosts(data))
      .catch(console.error);
  }, []);

  if (!aallPostsData) {
    return (
      <div>
        <h4>Loading...</h4>
      </div>
    );
  }
  return (
    <>
      <div className="widget-post-bx">
        {aallPostsData.map((item, index) => (
          <div className="widget-post clearfix" key={index}>
            <div className="sx-post-date text-center text-uppercase text-white">
              <strong className="p-date">
                {format(new Date(item.publishedAt), "dd")}
              </strong>
              <span className="p-month">
                {format(new Date(item.publishedAt), "MMMM").substr(0, 3)} -
              </span>
              <span className="p-year">
                {format(new Date(item.publishedAt), "yyyy")}
              </span>
            </div>
            <div className="sx-post-info">
              <div className="sx-post-header">
                <h6 className="post-title" title={item.title}>
                  <NavLink to={`/project-detail/${item.slug.current}`}>
                    {item.title.substr(0, 30) + "..."}
                  </NavLink>
                </h6>
              </div>
              <div className="sx-post-meta">
                <ul>
                  <li className="post-author">
                    <i className="fa fa-user" />
                    By arK
                  </li>
                  <li className="post-comment">
                    <i className="fa fa-home" /> {item.category}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default FooterPost;
