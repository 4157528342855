import React from "react";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import Header from "../Common/Header";
import Plans1 from "../Elements/Plans1";
import ContactUs1 from "../Elements/ContactUs1";

var bnrimg = require("./../../images/banner/price.jpg");

const PricingTable = () => {
  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title="Pricing Table"
          pagename="pricing-table"
          description="The prices are just placeholders and should be customized based on your specific services, expertise, and market conditions."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENTG START */}

        <Plans1 />
        <ContactUs1 />

        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
};

export default PricingTable;
