import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Header from "../Common/Header";

var bnrimg = require("./../../images/banner/5.jpg");
var bgimg = require("./../../images/background/bg-4.png");

class Error extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Page not found"
            pagename="oops!"
            description="We're sorry, but the page you are looking for might have been moved, deleted, or does not exist."
            bgimage={bnrimg}
          />
          {/* SECTION CONTENTG START */}
          <div
            className="section-full mobile-page-padding p-tb150 bg-bottom-left bg-no-repeat"
            style={{
              backgroundImage: `url(${bgimg})`,
            }}
          >
            <div className="container">
              <div className="section-content">
                <div className="page-notfound row">
                  <div className="col-md-7">
                    <img src={require("./../../images/error-404.png")} alt="" />
                  </div>
                  <div className="col-md-5">
                    <strong>Page Not Found</strong>
                    <span className="title">Oops! 404</span>
                    <p>
                      The Page Requested Could not be foundwe're working on it
                    </p>
                    <NavLink
                      to={"/"}
                      title="Back to home"
                      className="site-button btn-half"
                    >
                      <span> Back to home</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Error;
