import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";
import FooterPost from "../Elements/FooterPost";

const Footer = () => {
  const Subnews = (e) => {
    e.preventDefault();
    alert("Submit Successfully!");
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer footer-large  footer-dark	footer-wide">
        {/* FOOTER BLOCKES START */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* ABOUT COMPANY */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_about">
                  {/*<h4 class="widget-title">About Company</h4>*/}
                  <div className="logo-footer clearfix p-b15">
                    <NavLink to={"./"}>
                      <img
                        src={require("./../../images/logo-light.png")}
                        alt="Inteshape"
                      />
                    </NavLink>
                  </div>
                  <p>
                    As the Design Head & Associate Partner at Arch4 Foundation
                    from 2008 to 2020, arK design space has spent the past 12
                    years shaping the landscape of Building Construction
                    Management & Construction.
                  </p>
                  <ul className="social-icons  sx-social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61550791378653&mibextid=ZbWKwL"
                        className="fa fa-facebook"
                        target="_blank"
                      ></a>
                    </li>
                    {/* <li>
                        <a
                          href="https://twitter.com"
                          className="fa fa-twitter"
                          target="_blank"
                        ></a>
                      </li> */}
                    <li>
                      <a
                        href="https://www.instagram.com/arkdesignspaces?igsh=MXJscnZjeWZpNzIwMQ%3D%3D"
                        className="fa fa-instagram"
                        target="_blank"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* RESENT POST */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget recent-posts-entry-date">
                  <h5 className="widget-title">Resent Post</h5>

                  <FooterPost />
                </div>
              </div>
              {/* USEFUL LINKS */}
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                <div className="widget widget_services inline-links">
                  <h5 className="widget-title">Useful links</h5>
                  <ul>
                    <li>
                      <NavLink to={"/about"}>About</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/services"}>Services</NavLink>
                    </li>
                    <li>
                      {/* <NavLink to={"/projects"}>Projects</NavLink> */}
                      <a href={"/projects"}>Projects</a>
                    </li>
                    <li>
                      <NavLink to={"/faq"}>Faq</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/contact-us"}>Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* CONTACT US */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_address_outer">
                  <h5 className="widget-title">Contact Us</h5>
                  <ul className="widget_address">
                    <li>
                      <b>arK designspace, Karthik Selvaraj</b>
                    </li>
                    <li>
                      No - 29A, 1st, Floor, 4th St, Peons Colony,
                      Koundampalayam, Coimbatore, Tamil Nadu 641030
                    </li>
                    <li>
                      arkdesignspaces@gmail.com <br />
                      info@arkdesignspace.com{" "}
                    </li>
                    <li>
                      +91 91504 49980 <br />
                      +91 83449 99980
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="call-to-action-wrap">
              <div className="row">
                <div className="col-lg-7 col-md-6">
                  <div className="call-to-action-left">
                    <h5 className="text-uppercase m-b10 m-t0">
                      Subscribe to our newsletter!
                    </h5>
                    <span>
                      Never Miss Anything From arK Designspace By Signing Up To
                      Our Newsletter.
                    </span>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="call-to-action-right">
                    <div className="widget_newsletter">
                      <div className="newsletter-bx">
                        <form method="post" onSubmit={Subnews}>
                          <div className="input-group">
                            <input
                              required
                              name="news-letter"
                              className="form-control"
                              placeholder="ENTER YOUR EMAIL"
                              type="email"
                            />
                            <span className="input-group-btn">
                              <button type="submit" className="site-button">
                                <i className="fa fa-paper-plane-o" />
                              </button>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER COPYRIGHT */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              <div className="sx-footer-bot-left">
                <span className="copyrights-text">
                  © 2024 arK design space.
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Switcher />
    </>
  );
};

export default Footer;
