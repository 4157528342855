import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("../assets/js/mobilenav.js");
  });

  return (
    <>
      <ul className="nav navbar-nav">
        <li className={location.pathname === "/" ? "activeCL" : ""}>
          <NavLink to="/">Home</NavLink>
        </li>
        <li className={location.pathname === "/about" ? "activeCL" : ""}>
          <NavLink to="/about">About us</NavLink>
        </li>
        {/* <li>
          <NavLink to={""}>Pages</NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to={""}>Services</NavLink>
              <ul className="sub-menu">
                <li>
                  <NavLink to={"/services-1"}>Services 1</NavLink>
                </li>
                <li>
                  <NavLink to={"/services-2"}>Services 2</NavLink>
                </li>
                <li>
                  <NavLink to={"/services-detail"}>Service Detail</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to={""}>Team</NavLink>
              <ul className="sub-menu">
                <li>
                  <NavLink to={"/team-1"}>Our Team 1</NavLink>
                </li>
                <li>
                  <NavLink to={"/team-2"}>Our Team 2</NavLink>
                </li>
                <li>
                  <NavLink to={"/team-single"}>Team Detail</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to={"/our-history"}>Our History</NavLink>
            </li>
            <li>
              <NavLink to={"/icon-font"}>Fonts Icons</NavLink>
            </li>
            <li>
              <NavLink to={"/error-404"}>Error 404</NavLink>
            </li>
          </ul>
        </li> */}
        <li className={location.pathname === "/projects" ? "activeCL" : ""}>
          <a href="/projects">Projects</a>
        </li>
        <li className={location.pathname === "/services" ? "activeCL" : ""}>
          <NavLink to={"/services"}>Services</NavLink>
        </li>
        {/* <li>
          <NavLink to={""}>Blog</NavLink>
          <ul className="sub-menu">
            <li>
              <NavLink to={"/blog-grid"}>Blog Grid</NavLink>
            </li>
            <li>
              <NavLink to={"/blog-listing"}>Blog Listing</NavLink>
            </li>
            <li>
              <NavLink to={"/blog-masonry"}>Blog Masonry</NavLink>
            </li>
            <li>
              <NavLink to={"/blog-single"}>Blog Single</NavLink>
            </li>
            <li>
              <NavLink to={"/post-right-sidebar"}>Post Right Sidebar</NavLink>
            </li>
          </ul>
        </li> */}
        <li className={location.pathname === "/blogs" ? "activeCL" : ""}>
          <a href={"/blogs"}>Blogs</a>
        </li>
        <li
          className={location.pathname === "/pricing-table" ? "activeCL" : ""}
        >
          <NavLink to={"/pricing-table"}>Pricing Table</NavLink>
        </li>
        <li className={location.pathname === "/faq" ? "activeCL" : ""}>
          <NavLink to={"/faq"}>Faq</NavLink>
        </li>
        <li className={location.pathname === "/contact-us" ? "activeCL" : ""}>
          <NavLink to={"/contact-us"}>Contact us</NavLink>
        </li>
      </ul>
    </>
  );
};

export default Navigation;
